<template>
  <v-container>
    <div class="ml-2">
      <PageTitle
        text="Pump Meter Readings"
        class="font-weight-bold ft font-size-md"
      />
      <v-breadcrumbs
        class="ft font-weight-medium font-size-md px-1 pt-3"
        :items="[
          { text: 'Branches', href: '/app/branches' },
          { text: 'Pumps Meter Readings', disabled: true }
        ]"
      ></v-breadcrumbs>
    </div>

    <v-row
      align="center"
      justify="center"
      style="margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <v-progress-circular
          v-if="listLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div v-if="pumps.length > 0" class="d-flex flex-column flex-grow-1">
          <div class="d-flex flex-row flex-grow-1 justify-end">
            <div class="d-flex flex-column">
              <v-autocomplete
                outlined
                dense
                label="Choose a branch"
                v-model="selectedBranch"
                :items="branches"
                item-text="name"
                item-value="id"
                class=" ft font-size-md font-weight-medium"
              >
                <template #item="{item}">
                  <span class=" px-2 py-3 ft font-weight-medium">
                    {{ item.name }}
                  </span>
                </template>
                <template #no-data>
                  <span class=" px-4 py-2 ft font-weight-medium"
                    >No branches available</span
                  >
                </template>
              </v-autocomplete>
            </div>
          </div>
          <div class="d-flex flex-row flex-grow-1 ">
            <ValidationObserver
              v-if="pumps.length > 0"
              tag="div"
              class="d-flex flex-row flex-grow-1"
              v-slot="{ handleSubmit }"
            >
              <form
                style="width: 100%;"
                @submit.prevent="handleSubmit(confirmationAlert)"
              >
                <div
                  class="d-flex flex-column flex-grow-1 pa-4   grey lighten-4"
                  style="border-radius: 8px"
                >
                  <v-expansion-panels flat>
                    <v-expansion-panel v-for="record in pumps" :key="record.id">
                      <v-expansion-panel-header
                        v-if="record.nozzles && record.nozzles.length > 0"
                      >
                        <span class=" ft font-weight-medium">
                          Pump:
                          <v-chip small label class=" ft mr-2">{{
                            record.name
                          }}</v-chip>
                          Number Of Nozzles:
                          <v-chip small label class="ft">
                            {{ record.numberOfNozzles }}</v-chip
                          >
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div
                          v-if="record && record.nozzles.length > 0"
                          class="d-flex flex-column flex-grow-1 flex-wrap"
                        >
                          <div
                            v-for="(nozzle, idx) in record.nozzles"
                            :key="nozzle.id"
                            class="d-flex flex-column flex-fill flex-grow-1 flex-shrink-1 px-3 ma-2"
                            style="border-radius: 8px;"
                          >
                            <div
                              class="d-flex flex-row font-weight-bold black--text mt-3"
                              style="justify-content: space-between;"
                            >
                              <span class="ft font-weight-bold"
                                >{{ `Nozzle ${idx + 1}` }} /
                                {{ nozzle.productName }}
                              </span>
                            </div>
                            <div
                              class="d-flex flex-row flex-grow-1 flex-fill mt-1"
                            >
                              <v-text-field
                                label="Product Name"
                                background-color="white"
                                readonly
                                :value="nozzle.productName"
                                class="font-weight-medium ma-1"
                                hint="Product Name"
                                outlined
                              />
                              <ValidationProvider
                                tag="div"
                                class="d-flex flex-grow-1 flex-row"
                                v-slot="{ errors }"
                                rules="required|decimals"
                              >
                                <v-text-field
                                  :error-messages="errors[0]"
                                  label="Meter Reading"
                                  :value="nozzle.meterReading"
                                  background-color="white"
                                  class="ft font-weight-medium ma-1"
                                  hint="Opening meter readings of the pump"
                                  outlined
                                />
                              </ValidationProvider>

                              <ValidationProvider
                                tag="div"
                                class="d-flex flex-grow-1 flex-row"
                                v-slot="{ errors }"
                                rules="decimals"
                              >
                                <v-text-field
                                  :error-messages="errors[0]"
                                  label="New Meter Reading"
                                  v-model="nozzle.newMeterReading"
                                  background-color="white"
                                  class="ft font-weight-medium ma-1"
                                  hint="New meter readings of the pump"
                                  outlined
                                />
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <div class="d-flex mt-4 flex-column align-end">
                  <v-btn type="submit" color="primary">
                    <span class="ft font-weight-medium"
                      >Reset Meter Readings</span
                    >
                  </v-btn>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <EmptyPage
          v-else
          class="mt-10"
          title="Reset Nozzle Meter Readings"
          :image="require(`@/assets/oil-barrel.png`)"
          :imageWidth="'100px'"
          :subTitle="`Choose a branch to reset the nozzles`"
        >
          <template #action>
            <v-autocomplete
              outlined
              dense
              label="Choose a branch"
              v-model="selectedBranch"
              :items="branches"
              item-text="name"
              item-value="id"
              class=" ft font-weight-medium font-weight-medium"
            >
              <template #item="{item}">
                <span class=" px-2 py-3 ft font-weight-medium">
                  {{ item.name }}
                </span>
              </template>
              <template #no-data>
                <span class=" px-4 py-2 ft font-weight-medium"
                  >No branches available</span
                >
              </template>
            </v-autocomplete>
          </template>
        </EmptyPage>
      </v-col>
    </v-row>
    <ConfirmationDialog
      :state="dialog('alert')"
      title="Reset Opening Meter Readings"
      @close="close"
      :loading="isLoading"
      @save="resetMeterReadings"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../../components/PageTitle";
import SnackBarMixins from "../../../mixins/SnackBarMixins";
import DialogMixins from "../../../mixins/DialogMixins";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
import { decimals } from "../../utils/resolvers";

extend("numeric", {
  ...numeric,
  message: "Input field must contain only numbers"
});
extend("required", {
  ...required,
  message: "Input field is required"
});

extend("decimals", decimals);

export default {
  name: "Managers",
  components: {
    ValidationProvider,
    ValidationObserver,
    PageTitle,
    ConfirmationDialog: () => import(`../../../components/ConfirmationDialog`),
    SnackBar: () => import(`../../../components/SnackBar`),
    EmptyPage: () => import(`../../../components/EmptyPage.vue`)
  },
  data() {
    return {
      regions: [
        "Ahafo",
        "Ashanti",
        "Bono",
        "Bono East",
        "Central",
        "Eastern",
        "Greater Accra",
        "Northern",
        "North East",
        "Oti ",
        "Savannah",
        "Upper East",
        "Upper West",
        "Volta",
        "Western",
        "Western North"
      ],
      isMounted: false,
      options: {},
      selectedBranch: null
    };
  },
  mixins: [SnackBarMixins, DialogMixins],
  computed: {
    ...mapGetters({
      listLoading: "getIsListLoading",
      isLoading: "getIsLoading",
      branches: "branches/getAllBranches",
      paginate: "branches/getPagination",
      pumps: "branches/getAllPumps"
    }),
    headers() {
      return [
        {
          text: "",
          value: "avatar"
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Location", value: "location" },
        { text: "Telephone", value: "telephoneNumber" },
        { text: "Region", value: "region" },
        { text: "Action", value: "actions", align: "center" }
      ];
    }
  },
  watch: {
    selectedBranch(item) {
      if (item) {
        this.$router
          .replace({
            query: {
              branchId: item
            }
          })
          .catch();
        this.$store.dispatch("branches/branchPumps", {
          branchId: item
        });
      }
      console.log(item);
    },
    options: {
      handler(value) {
        if (this.isMounted) {
          const { page, itemsPerPage } = value;
          this.$store.dispatch("branches/list", {
            isPaginated: true,
            page,
            limit: itemsPerPage
          });
        }
      },
      deep: true
    }
  },

  created() {
    let payload = {
      page: this.options.page,
      limit: this.options.limit,
      isPaginated: true
    };
    this.$store.dispatch("branches/list", payload);
    this.$store.dispatch("branches/defaultRole", "site-manager");
    this.selectedBranch = this.$route.query.branchId;
    this.$store.dispatch("branches/branchPumps", {
      branchId: this.$route.query.branchId
    });
  },
  mounted() {
    this.isMounted = true;
  },
  filters: {
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },

  methods: {
    addBranch(payload) {
      this.$store.dispatch("branches/create", payload);
    },
    editBranch(payload) {
      this.$store.dispatch("branches/update", payload);
    },
    addBranchAdmin(user) {
      let payload = {
        ...user,
        userType: "site-manager",
        branchId: this.$store.getters["branches/getBranchDetails"]?.id
      };
      this.$store.dispatch("branches/addBranchAdmin", payload);
    },
    confirmationAlert() {
      this.open("alert");
    },
    resetMeterReadings() {
      const nozzles = this.pumps
        .map(pump => pump.nozzles)
        .reduce((acc, cur) => {
          acc.push(...cur);
          return acc;
        }, [])
        .filter(
          nozzle =>
            nozzle.newMeterReading &&
            Number(nozzle.meterReading) !== Number(nozzle.newMeterReading)
        );
      this.$store.dispatch("branches/updateMeterReadings", {
        branchId: this.$route.query.branchId,
        nozzles
      });
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
