var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"font-weight-bold ft font-size-md",attrs:{"text":"Pump Meter Readings"}}),_c('v-breadcrumbs',{staticClass:"ft font-weight-medium font-size-md px-1 pt-3",attrs:{"items":[
        { text: 'Branches', href: '/app/branches' },
        { text: 'Pumps Meter Readings', disabled: true }
      ]}})],1),_c('v-row',{staticStyle:{"margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.listLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.pumps.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-autocomplete',{staticClass:" ft font-size-md font-weight-medium",attrs:{"outlined":"","dense":"","label":"Choose a branch","items":_vm.branches,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:" px-2 py-3 ft font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:" px-4 py-2 ft font-weight-medium"},[_vm._v("No branches available")])]},proxy:true}],null,false,730568851),model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}})],1)]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 "},[(_vm.pumps.length > 0)?_c('ValidationObserver',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('form',{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmationAlert)}}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 pa-4   grey lighten-4",staticStyle:{"border-radius":"8px"}},[_c('v-expansion-panels',{attrs:{"flat":""}},_vm._l((_vm.pumps),function(record){return _c('v-expansion-panel',{key:record.id},[(record.nozzles && record.nozzles.length > 0)?_c('v-expansion-panel-header',[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v(" Pump: "),_c('v-chip',{staticClass:" ft mr-2",attrs:{"small":"","label":""}},[_vm._v(_vm._s(record.name))]),_vm._v(" Number Of Nozzles: "),_c('v-chip',{staticClass:"ft",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(record.numberOfNozzles))])],1)]):_vm._e(),_c('v-expansion-panel-content',[(record && record.nozzles.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-wrap"},_vm._l((record.nozzles),function(nozzle,idx){return _c('div',{key:nozzle.id,staticClass:"d-flex flex-column flex-fill flex-grow-1 flex-shrink-1 px-3 ma-2",staticStyle:{"border-radius":"8px"}},[_c('div',{staticClass:"d-flex flex-row font-weight-bold black--text mt-3",staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:"ft font-weight-bold"},[_vm._v(_vm._s(("Nozzle " + (idx + 1)))+" / "+_vm._s(nozzle.productName)+" ")])]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-fill mt-1"},[_c('v-text-field',{staticClass:"font-weight-medium ma-1",attrs:{"label":"Product Name","background-color":"white","readonly":"","value":nozzle.productName,"hint":"Product Name","outlined":""}}),_c('ValidationProvider',{staticClass:"d-flex flex-grow-1 flex-row",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium ma-1",attrs:{"error-messages":errors[0],"label":"Meter Reading","value":nozzle.meterReading,"background-color":"white","hint":"Opening meter readings of the pump","outlined":""}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-grow-1 flex-row",attrs:{"tag":"div","rules":"decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium ma-1",attrs:{"error-messages":errors[0],"label":"New Meter Reading","background-color":"white","hint":"New meter readings of the pump","outlined":""},model:{value:(nozzle.newMeterReading),callback:function ($$v) {_vm.$set(nozzle, "newMeterReading", $$v)},expression:"nozzle.newMeterReading"}})]}}],null,true)})],1)])}),0):_vm._e()])],1)}),1)],1),_c('div',{staticClass:"d-flex mt-4 flex-column align-end"},[_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Reset Meter Readings")])])],1)])]}}],null,false,2063248571)}):_vm._e()],1)]):_c('EmptyPage',{staticClass:"mt-10",attrs:{"title":"Reset Nozzle Meter Readings","image":require("@/assets/oil-barrel.png"),"imageWidth":'100px',"subTitle":"Choose a branch to reset the nozzles"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-autocomplete',{staticClass:" ft font-weight-medium font-weight-medium",attrs:{"outlined":"","dense":"","label":"Choose a branch","items":_vm.branches,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:" px-2 py-3 ft font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:" px-4 py-2 ft font-weight-medium"},[_vm._v("No branches available")])]},proxy:true}]),model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}})]},proxy:true}])})],1)],1),_c('ConfirmationDialog',{attrs:{"state":_vm.dialog('alert'),"title":"Reset Opening Meter Readings","loading":_vm.isLoading},on:{"close":_vm.close,"save":_vm.resetMeterReadings}}),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }